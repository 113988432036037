import React from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { Link, Redirect } from 'react-router-dom';
import {
  Placeholder,
  RichText,
  Text,
} from '@sitecore-jss/sitecore-jss-react';
// import { withNamespaces } from 'react-i18next';
import AvailabilityStatus from './components/custom/AvailabilityStatus/AvailabilityStatus';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import FooterLinks from './components/custom/FooterLinks/FooterLinks';

import isRecipient from './utilities/isRecipient';
import { authenticationService } from './_services';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    //console.log('props: ', props);
    this.state = {};
  }

  render() {
    const {
      route,
      chat,
      onlineSupport,
      serviceRequests,
      showSitemap,
    } = this.props;

    // text label strings
    const statusLabelOnlineSupport =
      onlineSupport === 'true' ? 'Available' : 'Offline';
    const statusLabelChat = chat === 'true' ? 'Online' : 'Offline';
    const statusLabelServiceRequests =
      serviceRequests === 'true' ? '' : 'Coming soon';

    function handleDisabled(e) {
      e.preventDefault();
    }

    function handleChatClick(e) {
      e.preventDefault();
      // chatbot
      const findChatBtn = document.querySelectorAll(
        '.embeddedServiceHelpButton button.helpButtonEnabled',
      );

      if (window.embedded_svc && findChatBtn.length > 0)
        window.embedded_svc.liveAgentAPI.startChat();

      // miaw
      const findMiawButton = document.querySelectorAll(
        '.embedded-messaging',
      );
      if (
        window.embeddedservice_bootstrap &&
        findMiawButton.length > 0
      )
        window.embeddedservice_bootstrap.utilAPI.launchChat();
    }

    function showModal(e) {
      e.preventDefault();
      let modal = document.getElementsByClassName(
        'modal--cant-see-my-device',
      )[0];
      modal.classList.add('is-active');
      let html = document.documentElement;
      html.classList.add('is-clipped');
    }

    function handleServiceRequestLink(e) {
      // check if logged in
      const token = authenticationService.currentUserValue;
      const decoded = token && jwtDecode(token);
      const currentUserAttributes =
        authenticationService.currentUserAttributes;
      const personas =
        currentUserAttributes &&
        JSON.parse(currentUserAttributes).personas;

      const appAccess =
        decoded && decoded['https://www.cochlear.com/app'];
      const countryCode =
        decoded && decoded['https://www.cochlear.com/country_code'];
      const userType =
        decoded && decoded['https://www.cochlear.com/user_type'];
      const locale = decoded && decoded['locale'];
      // logged in user - check token exists and user has access
      // checking persona cookie too. checking for a patient/carer scenario
      if (
        token &&
        appAccess &&
        // checking persona cookie too for carers that have patient/carer scenario
        // because their usertype is carer, they dont have ds app claim
        // make sure user is us and does not allow pr
        countryCode.toLowerCase() === 'us' &&
        (appAccess.includes('ds') ||
          (userType.toLowerCase() === 'carer' &&
            isRecipient(personas)))
      ) {
        return true;
      }

      // logged in user - NO DS CLAIM
      if (token && appAccess) {
        authenticationService.setReferrer('location', 'goBackUrl');
        return (
          <Redirect
            to={{
              pathname: `/us/en/support/not-authorized`,
              state: {
                countryCode,
                userType,
                locale,
              },
            }}
          />
        );
      }

      // not logged in
      // apply cookie for referrer as 'service-request' path
      // apply cookie for back/return button as current page url
      authenticationService.setReferrer('sr');
      authenticationService.setReferrer('location', 'goBackUrl');
      return true;
    }

    return (
      <footer className="footer">
        <section
          className={`footer__sitemap ${
            showSitemap ? '' : 'is-hidden'
          }`}
        >
          <div className="container">
            {/* menu--columns */}
            <div className="columns is-centered">
              <div className="column is-11-desktop">
                <div className="footer__sitemap__header">
                  <h2 className="title is-2">Support sitemap</h2>
                  <Placeholder
                    name="jss-breadcrumb"
                    rendering={route}
                  />
                  <hr />
                </div>

                <Accordion
                  allowZeroExpanded={true}
                  className="columns is-multiline"
                >
                  <AccordionItem className="column is-one-quarter">
                    <div className="menu">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          Nucleus<sup>&reg;</sup>&nbsp;support
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          {route.fields.footer.fields.Nucleus.map(
                            (x, index) => {
                              return (
                                <li key={index}>
                                  <Link
                                    data-ecategory="Sitemap Links"
                                    to={x.fields.link.value.href}
                                  >
                                    {x.fields.link.value.text}
                                  </Link>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                  <AccordionItem className="column is-one-quarter">
                    <div className="menu">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          Kanso<sup>&reg;</sup>&nbsp;support
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          {route.fields.footer.fields.Kanso.map(
                            (x, index) => {
                              return (
                                <li key={index}>
                                  <Link
                                    data-ecategory="Sitemap Links"
                                    to={x.fields.link.value.href}
                                  >
                                    {x.fields.link.value.text}
                                  </Link>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                  <AccordionItem className="column is-one-quarter">
                    <div className="menu">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          Baha<sup>&reg;</sup>&nbsp;support
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          {route.fields.footer.fields.Baha.map(
                            (x, index) => {
                              return (
                                <li key={index}>
                                  <Link
                                    data-ecategory="Sitemap Links"
                                    to={x.fields.link.value.href}
                                  >
                                    {x.fields.link.value.text}
                                  </Link>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                  <AccordionItem className="column is-one-quarter">
                    <div className="menu">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          Osia<sup>&reg;</sup>&nbsp;support
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          {route.fields.footer.fields.Osia.map(
                            (x, index) => {
                              return (
                                <li key={index}>
                                  <Link
                                    data-ecategory="Sitemap Links"
                                    to={x.fields.link.value.href}
                                  >
                                    {x.fields.link.value.text}
                                  </Link>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                  <AccordionItem className="column">
                    <div className="menu show-availability">
                      <AccordionItemHeading className="title is-5">
                        <AccordionItemButton>
                          <span className="is-hidden-mobile">
                            Further
                          </span>{' '}
                          support options
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="columns colums--support-menu">
                          <div className="column is-one-quarter">
                            <ul>
                              <li>
                                <a
                                  className={`${
                                    onlineSupport === 'true'
                                      ? 'is-online'
                                      : 'is-offline'
                                  }`}
                                  href={`tel:+${route.fields.footer.fields.countryDialingCode.value}-${route.fields.footer.fields.phoneNumber.value}`}
                                  disabled={onlineSupport === 'false'}
                                  onClick={
                                    onlineSupport === 'false'
                                      ? handleDisabled
                                      : undefined
                                  }
                                  data-ecategory="Sitemap Links"
                                >
                                  <Text
                                    field={
                                      route.fields.footer.fields
                                        .callUs
                                    }
                                  />
                                  <span className="phone">
                                    <Text
                                      field={
                                        route.fields.footer.fields
                                          .phoneNumber
                                      }
                                    />
                                  </span>
                                  <AvailabilityStatus
                                    label={
                                      statusLabelOnlineSupport || ''
                                    }
                                    showLabel={true}
                                    class={
                                      onlineSupport === 'true'
                                        ? 'is-online'
                                        : 'is-offline'
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/"
                                  className={`${
                                    chat === 'true'
                                      ? 'is-online'
                                      : 'is-offline'
                                  }`}
                                  disabled={chat === 'false'}
                                  onClick={
                                    chat === 'false'
                                      ? handleDisabled
                                      : handleChatClick
                                  }
                                  data-ecategory="Sitemap Links"
                                >
                                  <Text
                                    field={
                                      route.fields.footer.fields.chat
                                    }
                                  />
                                  <AvailabilityStatus
                                    label={statusLabelChat || ''}
                                    showLabel={true}
                                    class={
                                      chat === 'true'
                                        ? 'is-online'
                                        : 'is-offline'
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <Link
                                  to={`/${
                                    route.fields.footer.url.split(
                                      '/',
                                    )[1]
                                  }/${
                                    route.fields.footer.url.split(
                                      '/',
                                    )[2]
                                  }/support/service-request`}
                                  className={`${
                                    serviceRequests === 'true'
                                      ? ''
                                      : 'is-coming-soon'
                                  }`}
                                  disabled={
                                    serviceRequests === 'false'
                                  }
                                  onClick={
                                    serviceRequests === 'false'
                                      ? handleDisabled
                                      : handleServiceRequestLink
                                  }
                                  data-ecategory="Sitemap Links"
                                >
                                  <Text
                                    field={
                                      route.fields.footer.fields
                                        .submitRepair
                                    }
                                  />
                                  {serviceRequests === 'false' && (
                                    <div className="availability-status availability-status--is-coming-soon">
                                      {statusLabelServiceRequests}
                                    </div>
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="column is-1"></div>
                          <div className="column is-one-third">
                            <ul>
                              <li>
                                <a
                                  data-ecategory="Footer Links"
                                  href={
                                    route.fields.footer.fields
                                      .contactUsLink.value
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="external__link"
                                >
                                  <Text
                                    field={
                                      route.fields.footer.fields
                                        .onlineEnquiry
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  data-ecategory="Footer Links"
                                  href={
                                    route.fields.footer.fields
                                      .deviceSupportLink.value
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="external__link"
                                >
                                  <Text
                                    field={
                                      route.fields.footer.fields
                                        .visitSupport
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  data-ecategory="Footer Links"
                                  href="/"
                                  className="help__link include-icon-before"
                                  onClick={showModal}
                                >
                                  <Text
                                    field={
                                      route.fields.footer.fields
                                        .processorNotHere
                                    }
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                </Accordion>

                <RichText
                  field={route.fields.footer.fields.disclaimer}
                />
              </div>
            </div>
          </div>
        </section>
        <FooterLinks
          lang={route.fields.footer.fields.languageCode.value}
          country={route.fields.footer.fields.countryCode.value}
        />
      </footer>
    );
  }
}

// Specifies the default values for props:
Footer.defaultProps = {
  showSitemap: true,
};

Footer.propTypes = {
  showSitemap: PropTypes.bool,
};

// Footer = withNamespaces()(Footer);
export default Footer;
