import React from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import jwtDecode from 'jwt-decode';

import { authenticationService, environment } from './_services';
import componentFactory from './temp/componentFactory';
import SitecoreContextFactory from './lib/SitecoreContextFactory';
import RouteHandler from './RouteHandler';
import NotAuthorized from './NotAuthorized';
import ScrollToTop from './ScrollToTop';

import isRecipient from './utilities/isRecipient';
import { history } from './_helpers';
import config from './common/config';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:country([a-z]{2})/:lang([a-z]{2}-[A-Z]{2})/support/:sitecoreRoute*',
  '/:country([a-z]{2})/:lang([a-z]{2})/support/:sitecoreRoute*',
];

// all these should route to service requests
export const srRoutePatterns = [
  '/:country([a-z]{2})/:lang([a-z]{2}-[A-Z]{2})/support/service-request',
  '/:country([a-z]{2})/:lang([a-z]{2})/support/service-request',
];

const { authenticate } = config;
const authAPI = authenticate[environment].authorization;

const PrivateRoute = React.forwardRef((props, ref) => {
  const token = authenticationService.currentUserValue;
  const decoded = token && jwtDecode(token);
  const currentUserAttributes =
    authenticationService.currentUserAttributes;
  const personas =
    currentUserAttributes &&
    JSON.parse(currentUserAttributes).personas;

  const appAccess =
    decoded && decoded['https://www.cochlear.com/app'];
  const countryCode =
    decoded && decoded['https://www.cochlear.com/country_code'];
  const userType =
    decoded && decoded['https://www.cochlear.com/user_type'];
  const locale = decoded && decoded.locale;
  const { render } = props;
  // check token exists and user has access

  if (
    token &&
    appAccess &&
    // checking persona cookie too for carers that have patient/carer scenario
    // because their usertype is carer, they dont have ds app claim
    // make sure user is us and does not allow pr
    countryCode.toLowerCase() === 'us' &&
    (appAccess.includes('ds') ||
      (userType.toLowerCase() === 'carer' && isRecipient(personas)))
  ) {
    return routePatterns.map((routePattern) => {
      return (
        <Route
          key={routePattern}
          path={routePattern}
          render={render}
          ref={ref}
          currentUser={token}
          userId={decoded['https://www.cochlear.com/cochlear_id']}
        />
      );
    });
  }

  // NO DS CLAIM
  if (token && appAccess) {
    return (
      <Redirect
        to={{
          pathname: `/us/en/support/not-authorized`,
          state: {
            countryCode,
            userType,
            locale,
          },
        }}
      />
    );
  }

  return (
    <Route
      path="/"
      component={() => {
        // Not logged in so redirect to login page
        // set this location as the referrer url in cookie
        authenticationService.setReferrer();

        // window check - server will fail without it
        if (typeof window !== 'undefined') {
          window.location.href = `${authAPI}/authorize?app=ds`;
          return null;
        }
        return null;
      }}
    />
  );
});

// switch authentication endpoints for local development
// to get callback urls from lambda service set process environement to LOCAL at runtime
// $ REACT_APP_TEST_VAR=LOCAL jss start
// if REACT_APP_TEST_VAR=LOCAL is true, config.authenticate calls different endpoint for local development

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
//const AppRoot = ({ path, Router, graphQLClient }) => {
class AppRoot extends React.Component {
  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      sid: null,
      userId: null,
      currentUser: null,
    };
    // ref passed through to logout function
    // if logging out from a private path, the logout URL callback is different
    this.privatePath = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      this.setUserDetails(authenticationService.currentUserValue);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setUserDetails(user) {
    const { sid } = this.state;
    const currentUser = user;
    if (currentUser) {
      const decoded = jwtDecode(currentUser);
      if (decoded.sid !== sid) {
        this.setState({
          sid: decoded.sid,
          userId: decoded['https://www.cochlear.com/cochlear_id'],
          currentUser,
        });
      }
    }
  }

  render() {
    const { path, Router, graphQLClient } = this.props;
    const { userId, currentUser } = this.state;

    const routeRenderFunction = (props) => {
      return (
        <RouteHandler
          route={props}
          currentUser={currentUser}
          userId={userId}
          privatePath={this.privatePath}
        />
      );
    };

    return (
      <ApolloProvider client={graphQLClient}>
        <SitecoreContext
          componentFactory={componentFactory}
          contextFactory={SitecoreContextFactory}
        >
          <Router location={path} context={{}} history={history}>
            <ScrollToTop>
              <Switch>
                <Route
                  exact
                  path={`/us/en/support/not-authorized`}
                  component={NotAuthorized}
                />
                {srRoutePatterns.map((srRoutePattern) => (
                  <PrivateRoute
                    key={srRoutePattern}
                    path={srRoutePattern}
                    render={routeRenderFunction}
                    ref={this.privatePath}
                  />
                ))}
                {routePatterns.map((routePattern) => (
                  <Route
                    key={routePattern}
                    path={routePattern}
                    render={routeRenderFunction}
                  />
                ))}
              </Switch>
            </ScrollToTop>
          </Router>
        </SitecoreContext>
      </ApolloProvider>
    );
  }
}

export default AppRoot;
