// config file
const config = {
  env: process.env.REACT_APP_DRX_ENV,
  devMode: process.env.REACT_APP_DRX_ENV === 'DEV' ? true : false, // set to true for development
  country: 'us',
  lang: 'en',
  utcOffset: -6,
  countryDialingCode: 1,
  countryName: 'United States',
  phoneNumber: '877-651-7001',
  showChatbot: false,
  cookieDomain: {
    LOCAL: 'localhost',
    DEV: '.cochlear.cloud',
    SIT: '.cochlear.cloud',
    UAT: '.cochlear.com',
    PRD: '.cochlear.com',
  },
  baseURL: {
    LOCAL: 'http://localhost',
    DEV: 'https://dev-drx.cx.nonp.cochlear.cloud/',
    SIT: 'https://sit-drx.cx.nonp.cochlear.cloud/',
    UAT: 'https://www2.cochlear.com/',
    PRD: 'https://support.cochlear.com/',
  },
  drxMain: {
    LOCAL: 'https://dmdev.cx.nonp.cochlear.cloud',
    DEV: 'https://dmdev.cx.nonp.cochlear.cloud',
    SIT: 'https://dmsit.cx.nonp.cochlear.cloud',
    UAT: 'https://www2.cochlear.com',
    PRD: 'https://www.cochlear.com',
  },
  authorizeUser: {
    LOCAL: 'https://authnz-dev.cx.nonp.cochlear.cloud',
    DEV: 'https://authnz-dev.cx.nonp.cochlear.cloud',
    SIT: 'https://authnz-sit.cx.nonp.cochlear.cloud',
    UAT: 'https://id-uat.cochlear.com',
    PRD: 'https://id-prd.cochlear.com',
  },
  cochlearMCR: {
    LOCAL: 'https://recipients-dev11.mycochlear.com',
    DEV: 'https://recipients-dev11.mycochlear.com',
    SIT: 'https://recipients-sit.mycochlear.com',
    UAT: 'https://recipients-uat.mycochlear.com',
    PRD: 'https://recipients.mycochlear.com',
  },
  CochlearDotCom: {
    LOCAL: 'http://localhost/',
    DEV: 'https://node.dev.cms.cochlear.cloud/',
    SIT: 'https://node.sit.cms.cochlear.cloud/',
    UAT: 'https://www2.cochlear.com/',
    PRD: 'https://www.cochlear.com/',
  },
  assetsEndpoint: {
    LOCAL: 'https://assets.stg.cochlear.com',
    DEV: 'https://assets.stg.cochlear.com',
    SIT: 'https://assets.stg.cochlear.com',
    UAT: 'https://assets.stg.cochlear.com',
    PRD: 'https://assets.cochlear.com',
  },
  storeLogout: {
    LOCAL: '',
    DEV: '',
    SIT: 'https://bfrl-004.sandbox.us01.dx.commercecloud.salesforce.com/s/Cochlear',
    UAT: 'https://www2.cochlear.com/',
    PRD: 'https://www.cochlear.com/',
  },
  storeLogoutToken: {
    LOCAL: '',
    DEV: '',
    SIT: 'c3RvcmVmcm9udDpHUytFVzIxLUdyZWF0QzBjaGxlYXI=',
    UAT: 'ZGV2czpncmVlbmF2b2NhZG8=',
    PRD: '',
  },
  gql: {
    LOCAL: {
      scCd: 'dmuat.cx.nonp.cochlear.cloud',
      apiKey: '%7BC8D77F78-6799-48EC-A2D9-0D4A9C0E34C1%7D',
      authToken: 'ZGV2czpncmVlbmF2b2NhZG8=',
      countryLanguageList: '',
    },
    DEV: {
      scCd: 'dmuat.cx.nonp.cochlear.cloud',
      apiKey: '%7BC8D77F78-6799-48EC-A2D9-0D4A9C0E34C1%7D',
      authToken: 'ZGV2czpncmVlbmF2b2NhZG8=',
      countryLanguageList: '',
    },
    SIT: {
      scCd: 'dmsit.cx.nonp.cochlear.cloud',
      apiKey: '%7BC8D77F78-6799-48EC-A2D9-0D4A9C0E34C1%7D',
      authToken: 'ZGV2czpHcmVhdEMwY2hsZWFy',
      countryLanguageList: '',
    },
    UAT: {
      scCd: 'dmuat.cx.nonp.cochlear.cloud',
      apiKey: '%7BC8D77F78-6799-48EC-A2D9-0D4A9C0E34C1%7D',
      authToken: 'ZGV2czpncmVlbmF2b2NhZG8=',
      countryLanguageList: '',
    },
    PRD: {
      scCd: 'www.cochlear.com',
      apiKey: '%7BC373F5B9-AE6C-4E7B-B746-EEACB3D8EA0C%7D',
      authToken: '',
      countryLanguageList: '',
    },
  },
  authenticate: {
    LOCAL: {
      authorization: 'http://localhost:3003/drx/v1/auth',
      contents: 'http://localhost:3003/drx/v1/contents',
      patients: 'http://localhost:3003/drx/v1/patients',
      CIM_RecipientLogout:
        'https://cochlear--uob2.sandbox.my.site.com/Recipient/CIM_RecipientLogout',
    },
    DEV: {
      authorization: 'https://dev.api.cochlear.com/drx/v1/auth',
      contents: 'https://dev.api.cochlear.com/drx/v1/contents',
      patients: 'https://dev.api.cochlear.com/drx/v1/patients',
      CIM_RecipientLogout:
        'https://cochlear--uob2.sandbox.my.site.com/Recipient/CIM_RecipientLogout',
    },
    SIT: {
      authorization: 'https://sit.api.cochlear.com/drx/v1/auth',
      contents: 'https://sit.api.cochlear.com/drx/v1/contents',
      patients: 'https://sit.api.cochlear.com/drx/v1/patients',
      CIM_RecipientLogout:
        'https://cochlear--sit01.sandbox.my.site.com/Recipient/CIM_RecipientLogout',
    },
    UAT: {
      authorization: 'https://uat.api.cochlear.com/drx/v1/auth',
      contents: 'https://uat.api.cochlear.com/drx/v1/contents',
      patients: 'https://uat.api.cochlear.com/drx/v1/patients',
      CIM_RecipientLogout:
        'https://cochlear--uat.sandbox.my.site.com/Recipient/CIM_RecipientLogout',
    },
    PRD: {
      authorization: 'https://api.cochlear.com/drx/v1/auth',
      contents: 'https://api.cochlear.com/drx/v1/contents',
      patients: 'https://api.cochlear.com/drx/v1/patients',
      CIM_RecipientLogout:
        'https://cochlear.my.site.com/Recipient/CIM_RecipientLogout',
    },
  },
  webToMail: {
    LOCAL: {
      endpoint: 'https://sit.api.cochlear.com/drx/v1/utils/send-mail',
    },
    DEV: {
      endpoint: 'https://dev.api.cochlear.com/drx/v1/utils/send-mail',
    },
    SIT: {
      endpoint: 'https://sit.api.cochlear.com/drx/v1/utils/send-mail',
    },
    UAT: {
      endpoint: 'https://uat.api.cochlear.com/drx/v1/utils/send-mail',
    },
    PRD: {
      endpoint: 'https://api.cochlear.com/drx/v1/utils/send-mail',
    },
  },
  miawChat: {
    DEV: {
      language: 'en_US',
      orgId: '00D3N000000HazT',
      embeddedServiceAPIName: 'CAM_MIAW_WebChat_Cochlear',
      siteUrl:
        'https://mycochlear--sitest.sandbox.my.site.com/ESWCAMMIAWWebChatCochl1706760160724',
      scrtUrl:
        'https://mycochlear--sitest.sandbox.my.salesforce-scrt.com',
    },
    SIT: {
      language: 'en_US',
      orgId: '00D3N000000HazT',
      embeddedServiceAPIName: 'CAM_MIAW_WebChat_Cochlear',
      siteUrl:
        'https://mycochlear--sitest.sandbox.my.site.com/ESWCAMMIAWWebChatCochl1706760160724',
      scrtUrl:
        'https://mycochlear--sitest.sandbox.my.salesforce-scrt.com',
    },
    UAT: {
      language: 'en_US',
      orgId: '00D9Q00000AJkSn',
      embeddedServiceAPIName: 'CAM_MIAW_WebChat',
      siteUrl:
        'https://mycochlear--uat.sandbox.my.site.com/ESWCAMMIAWWebChat1711242673129',
      scrtUrl:
        'https://mycochlear--uat.sandbox.my.salesforce-scrt.com',
    },
    PRD: {
      language: 'en_US',
      orgId: '00D5g000001FTeM',
      embeddedServiceAPIName: 'CAM_MIAW_WebChat',
      siteUrl:
        'https://mycochlear.my.site.com/ESWCAMMIAWWebChat1711242673129',
      scrtUrl: 'https://mycochlear.my.salesforce-scrt.com',
    },
  },
  chatSFHC: {
    LOCAL: {
      baseLiveAgentContentURL: 'c.la1-c1cs-lo3',
      baseLiveAgentURL: 'd.la1-c1cs-lo3',
      buttonChatName: 'CAMWebChat',
      buttonDeployId: '5720T0000004CDF',
      buttonEswLiveAgentDevName:
        'EmbeddedServiceLiveAgent_Parent04I0T0000004CMlUAM_186abd5a1ea',
      buttonId: '5730T0000004CHA',
      isOfflineSupportEnabled: false,
      salesforceId: '00D0T0000000N6E',
      sfEnv: 'mycochlear--devst.sandbox',
    },
    DEV: {
      baseLiveAgentContentURL: 'c.la1-c1cs-lo3',
      baseLiveAgentURL: 'd.la1-c1cs-lo3',
      buttonChatName: 'CAMWebChat',
      buttonDeployId: '5720T0000004CDF',
      buttonEswLiveAgentDevName:
        'EmbeddedServiceLiveAgent_Parent04I0T0000004CMlUAM_186abd5a1ea',
      buttonId: '5730T0000004CHA',
      isOfflineSupportEnabled: false,
      salesforceId: '00D0T0000000N6E',
      sfEnv: 'mycochlear--devst.sandbox',
    },
    SIT: {
      baseLiveAgentContentURL: 'c.la2-c1cs-fra',
      baseLiveAgentURL: 'd.la2-c1cs-fra',
      buttonChatName: 'Clio_Chatbot',
      buttonDeployId: '5726N000000sXuZ',
      buttonEswLiveAgentDevName:
        'EmbeddedServiceLiveAgent_Parent04I3N00000001bhUAA_18de3cd9a5d',
      buttonId: '5733N0000004G6P',
      isOfflineSupportEnabled: false,
      salesforceId: '00D3N000000HazT',
      sfEnv: 'mycochlear--sitest.sandbox',
    },
    UAT: {
      baseLiveAgentContentURL: 'c.la1-c1cs-lo3',
      baseLiveAgentURL: 'd.la1-c1cs-lo3',
      buttonChatName: 'Clio_Chatbot',
      buttonDeployId: '5726N000000sXuZ',
      buttonEswLiveAgentDevName:
        'EmbeddedServiceLiveAgent_Parent04I260000008PUeEAM_18df7d72436',
      buttonId: '5732600000001NF',
      isOfflineSupportEnabled: false,
      salesforceId: '00D260000009Ivu',
      sfEnv: 'mycochlear--uat.sandbox',
    },
    PRD: {
      baseLiveAgentContentURL: 'c.la3-c1-fra',
      baseLiveAgentURL: 'd.la3-c1-fra',
      buttonChatName: 'CAMWebChat',
      buttonDeployId: '5726N000000sXuZ',
      buttonEswLiveAgentDevName:
        'EmbeddedServiceLiveAgent_Parent04I0T0000004CMlUAM_186abd5a1ea',
      buttonId: '5736N000000wk2i',
      isOfflineSupportEnabled: false,
      salesforceId: '00D5g000001FTeM',
      sfEnv: 'mycochlear',
    },
  },
  auth0: {
    LOCAL: {
      customDomain: 'https://poc.id.cochlear.com',
      host: 'https://cochlear-poc.au.auth0.com',
      clientId: 'genWNPWs3VwRNhIUX43v8rw9SW93qFrq',
      logoutRedirectUrl:
        'https://authnz-dev.cx.nonp.cochlear.cloud/callback',
    },
    DEV: {
      customDomain: 'https://dev.id.cochlear.com',
      host: 'https://cochlear-dev.eu.auth0.com',
      clientId: 'j7JQUr4JeH7p16eLQAXIZ1diTrj1Z60u',
      logoutRedirectUrl:
        'https://authnz-dev.cx.nonp.cochlear.cloud/callback',
    },
    SIT: {
      customDomain: 'https://sit.id.cochlear.com',
      host: 'https://cochlear-sit.eu.auth0.com',
      clientId: 'fPARxTBd5XUM8T4i2Xhk8RMTPdABdqZg',
      logoutRedirectUrl:
        'https://authnz-sit.cx.nonp.cochlear.cloud/callback',
    },
    UAT: {
      customDomain: 'https://uat.id.cochlear.com',
      host: 'https://cochlear-uat.eu.auth0.com',
      clientId: '5WOxA6WfyBh1h2mFlJlbqAtdzpUUwKuR',
      logoutRedirectUrl: 'https://id-uat.cochlear.com/callback',
    },
    PRD: {
      customDomain: 'https://id.cochlear.com',
      host: 'https://cochlear.eu.auth0.com',
      clientId: 'pMveXXshi8UAKcRWLotFBj739ISp9lI8',
      logoutRedirectUrl: 'https://id-prd.cochlear.com/callback',
    },
  },
};

export default config;
