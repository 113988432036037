import config from '../common/config';

const { gql, env } = config;

export default function languageMapping(locale, country) {
  const defaultLanguage = 'en';
  // check if country is on the countryLanguageList, if it is return language as "en".
  // this is useful when we add a new non-english country but only have an english version to start with.
  const { countryLanguageList } = gql[env];
  if (countryLanguageList.includes(country)) return defaultLanguage;

  // check if locale is english. All english locales (eg, 'en-AU', 'en-US', etc)
  // need only return 'en'
  if (locale.substring(0, 2).toLowerCase() === 'en')
    return defaultLanguage;

  // transform token locale so that it matches format required by SC
  // eg this will transform ja_JP to ja-JP or it_IT to it-IT
  const languageCode = locale.substring(0, 2).toLowerCase();
  const countryCode = country.toUpperCase();
  return `${languageCode}-${countryCode}`;
}
